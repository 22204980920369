import service from "./axiosConfig";


// base
const base = {
  // url: 'https://admin.mbbrowser.com'
  url: 'https://api.yalala.com'
}

// user

const user = {
  /**
   *
   * @param accounts {accounts}
   * @param pwd {password}
   * @returns {Promise}
   */
  reg(accounts, pwd) {
    return service.post(`${base.url}/api/mbbrowser/reg`, {accounts, pwd})
  },
  /**
   *
   * @param accounts {accounts}
   * @param pwd {password}
   * @returns {Promise<any>}
   */
  login(accounts, pwd) {
    return service.post(`${base.url}/api/mbbrowser/login`, {accounts, pwd})
  }

}

export const getIp = {
  info(data = {}) {
    return service.post(`${base.url}/api/mbbrowser/clientinfo`, data)
  },
  country(ip) {
    return service.post(`${base.url}/api/mbbrowser/ipcountry`, {ip})
  }
}

export const getDns = {
  info(dnsid) {
    //return service.post(`${base.url}/api/mbbrowser/dns`, {dnsid})
    // return service.post(`${base.url}/api/mbbrowser/dnsv3`, {dnsid})
    return service.post(`${base.url}/api/mbbrowser/dnsv4`, {dnsid})
  }
}

export const getExchanges = {
  exchange() {
    return service.post(`${base.url}/api/mbbrowser/exchange`)
  },
  btc() {
    return service.post(`${base.url}/api/mbbrowser/coinrate`)
  }
}

export default {
  user, getIp, getDns, getExchanges
}
