import axios from 'axios'
import {getDns} from '@/utils/api'

import {onMounted, ref} from 'vue'

export default function fetchDns() {
  const dns = ref([
    {
      ip: 'N/A',
      geo: [{
        cc: 'N/A',
        country: 'N/A',
        isp: 'N/A',
        region: 'N/A',
        latitude: 'N/A',
        longitude: 'N/A'
      }]
    }
  ])
  const dnsFinish = ref(0)
  const errorCount = ref(0)
  const reqCount = ref(0)

  const getDnsData = new Promise((resolve, reject) => {
    const $chars = 'abcdefghijklmnopqrstuvwxyz1234567890'
    const $preChars = 'abcdef1234567890'
    const maxPos = $chars.length
    const maxPosPre = $preChars.length
    let id = ''
    for (let i = 0; i < 8; i++) {
      id += $preChars.charAt(Math.floor(Math.random() * maxPosPre))
    }
    id += '-'
    for (let i = 0; i < 45; i++) {
      id += $chars.charAt(Math.floor(Math.random() * maxPos))
    }
    console.log(id)
    for (let index = 0; index < 5; index++) {
      //const img = new Image()
      //img.src = 'https://' + id + index + '.dnsleaktest.ip8.com'
      //img.style.display = 'none'
      //img.onerror = errorHandle()
      // const checkerURL = 'https://' + id + index + '.strongleakstest.com/ajax.js'
      const checkerURL = 'https://' + index + '.' + id  +  '.test.dnsleaktest.org/test' + index + '.png'
      setTimeout(() => {
        axios({
          method: 'post',
          url: checkerURL,
          timeout: 1000,
          dataType: 'script'
        }).catch(() => {
          errorHandle()
        })
      }, 1)
    }
    function errorHandle() {
      errorCount.value ++
      console.log(errorCount.value)
      if (errorCount.value >= 5){
        // 请求dns信息
        getDns.info(id).then(res => {
          reqCount.value ++
          //如果code不是0，则再次请求，最多3次
          if (res.data.code === 0) {
            const resData = res.data.data
            //res.data.data 是数组，包含多个dns信息，最多保留9个
            const data = resData.slice(0, 9).map(item => {
              return {
                ip: item.ipAddress,
                geo: [{
                  cc: item.countryCode,
                  country: item.countryName,
                  isp: item.isp,
                  region: item.regionName,
                  latitude: 'N/A',
                  longitude: 'N/A',
                }]
              }
            })
            resolve(data)
          } else {
            if (reqCount.value < 3) {
              console.log('再次请求')
              errorHandle()
            } else {
              reject(-1)
            }
          }
        })
      }
    }


    setTimeout(()=>{
      reject(-2)
    }, 15000)
  })

  onMounted(() => {
    getDnsData.then((res)=> {
      if (res[0] !== undefined) {
        dnsFinish.value = 1
        dns.value = res
      } else {
        dnsFinish.value = -1
      }
    }).catch((err) => {
      console.log(err)
      dnsFinish.value = err
    })
  })

  return {
    dns, dnsFinish
  }
}
